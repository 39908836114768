//
// Testimonial
// --------------------------------------------------


// Testimonial

.testimonial {
  margin-bottom: 0;
  padding-top: $testimonial-mark-size * .4;

  .card-body { padding-top: $card-spacer-y * 2.1; }

  .testimonial-mark {
    position: absolute;
    top: -($testimonial-mark-size * .4);
    left: $card-spacer-x;
    width: $testimonial-mark-size;
    height: $testimonial-mark-size;
    @include border-radius($testimonial-mark-border-radius);
    background-color: $testimonial-mark-bg;
    color: $testimonial-mark-color;
    font: {
      size: $testimonial-mark-size;
      weight: 500;
    }
    text-align: center;
    box-shadow: $testimonial-box-shadow;
    z-index: 5;
    &::before {
      content: "''";
    }
  }
}
